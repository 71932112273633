import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import * as serviceWorker from './serviceWorker';
import { ThemeProvider } from 'styled-components';
import { BrowserRouter } from 'react-router-dom';
import * as theme from '@backyard/design-tokens/dist/omnia/theme';
import MainPanel from './components/MainPanel/MainPanel';
import pkgDtls from '../package.json';
import { isProduction } from 'omnia-utils-js';

// uncomment below line to add environment variable to run app locally
// window._env_ = { CONTACT_CENTER_ENABLED: true , APPLICATION_ENV: 'dev' }

ReactDOM.render(
  <ThemeProvider theme={theme}>
    <BrowserRouter>
      <span style={{ display: 'none' }}>{pkgDtls.version}</span>
      <MainPanel />
    </BrowserRouter>
  </ThemeProvider>,
  document.getElementById('root'),
);
const shouldMockBrowser = JSON.parse(localStorage.getItem('shouldMockBrowser'));
if (!isProduction() && shouldMockBrowser) {
  import('./mocks/initMockServiceWorker').then((initMockServiceWorker) => {
    initMockServiceWorker.default();
  });
}
serviceWorker.unregister();
