import React from 'react';
import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core/styles';
import StoreForm from './StoreForm';
import {
  StylesProvider,
  createGenerateClassName,
} from '@material-ui/core/styles';
import { logMFEEvent } from 'omnia-logger';

const generateClassName = createGenerateClassName({
  productionPrefix: 'SL',
  disableGlobal: true,
});

const useStyles = makeStyles((theme) => ({
  container: {
    height: '100vh',
    display: 'flex',
    maxWidth: '100%',
    background: '#012169',
  },
}));

const MainPanel = () => {
  const classes = useStyles();
  return (
    <StylesProvider generateClassName={generateClassName}>
      <Container className={classes.container}>
        <StoreForm />
      </Container>
    </StylesProvider>
  );
};
logMFEEvent({
  mfeName: "store-locator-mfe",
  mfeVersion: APP_VERSION
});
export default React.memo(MainPanel);
