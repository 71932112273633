const baseUrl = {
  development: '/',
  production: '/',
};

global.localStorage = {
  getItem: () => undefined,
};

const storeNumber = localStorage.getItem('storenumber') || '';

const appUrl = `https://apps.${storeNumber}.lowes.com`;
const baseAPIUrl = `${appUrl}/`;

const loginUrl = (storeNumber) => {
  return `https://dkr1.${storeNumber}.lowes.com/omnia/login/`;
};
const mappingUrl = (storeNumber) => {
  return `https://dkr1.${storeNumber}.lowes.com/omnia/login/services/fcgi-bin/apps/MGFService?action=StoreInfo`;
};
const listboxPadding = 8;
const ISTCLIENT = 'windowsIST';
const devUrl = 'https://dev.carbon.lowes.com/omniab/store/getTerminal/';
const stageUrl = 'https://stage.carbon.lowes.com/omniab/store/getTerminal/';
const prodUrl =
  'https://internal.carbon.gcp.lowes.com/omniab/store/getTerminal/';

const urlConfig = {
  dev: 'https://dev.carbon.lowes.com',
  stage: 'https://stage.carbon.lowes.com',
  prod: 'https://internal.carbon.gcp.lowes.com',
};

// isDevorStgEnvironment will return true if we are in dev/stg region, false in prod region.
const isDevorStgEnvironment = () => {
  let path = window.location.host;
  if (path.includes(devUrl) || path.includes(stageUrl)) {
    return true;
  } else {
    return false;
  }
};

const setDkrnumberforErrors = () => {
  if (isDevorStgEnvironment()) {
    localStorage.setItem('dkrNumber', '6574');
  } else {
    localStorage.setItem('dkrNumber', '2352');
  }
  return;
};
const STORE_LOCATOR_MFE_CODE = 'SLM';

module.exports = {
  baseUrl: baseUrl[process.env.NODE_ENV],
  appUrl,
  storeNumber,
  baseAPIUrl,
  listboxPadding,
  loginUrl,
  mappingUrl,
  ISTCLIENT,
  setDkrnumberforErrors,
  urlConfig,
  STORE_LOCATOR_MFE_CODE,
};
