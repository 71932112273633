import axios from 'axios';
import {
  getGcpEnvValues,
  getTokenInfo,
  generateCorrelationId,
} from 'omnia-utils-js';
import { STORE_LOCATOR_MFE_CODE } from './constants';

const getRequestConfigDefaults = (config) => {
  const gcpEnvValues = getGcpEnvValues();
  const realmId = gcpEnvValues['KEYCLOAK_AUTH_REALM'];
  const clientId = gcpEnvValues['KEYCLOAK_AUTH_CLIENT'];
  const { access_token: accessToken = '' } = getTokenInfo();
  return {
    ...config,
    metaData: { startTime: new Date() },
    headers: {
      ...config.headers,
      Authorization: `Bearer ${accessToken}`,
      'Login-Session-Token': '""',
      'las-application-id': clientId,
      'realm-id': realmId,
      'x-correlation-id': generateCorrelationId(STORE_LOCATOR_MFE_CODE),
    },
  };
};

export const getService = (serviceUrl, manualConfig) => {
  axios.interceptors.request.use(
    (config) => getRequestConfigDefaults(config),
    (error) => Promise.reject(error),
  );

  axios.interceptors.response.use(
    (response) => {
      response.config.metaData.endTime = new Date();
      response.config.metaData.duration =
        response.config.metaData.endTime - response.config.metaData.startTime;
      return response;
    },
    (error) => Promise.reject(error),
  );

  return axios
    .get(serviceUrl, manualConfig)
    .then((response) => ({ response: response.data, logResponse: response }));
};

export function postService(serviceUrl, bodyFormData) {
  axios.interceptors.request.use(
    (config) => {
      config.metaData = { startTime: new Date() };
      return config;
    },
    (error) => Promise.reject(error),
  );

  axios.interceptors.response.use(
    (response) => {
      response.config.metaData.endTime = new Date();
      response.config.metaData.duration =
        response.config.metaData.endTime - response.config.metaData.startTime;
      return response;
    },
    (error) => Promise.reject(error),
  );

  return axios
    .post(serviceUrl, bodyFormData)
    .then((response) => ({ response: response.data, logResponse: response }))
    .catch((error) => ({ error }));
}
