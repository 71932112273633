import React, { useEffect, useRef, useState, useCallback } from 'react';
import SearchIcon from '@material-ui/icons/Search';
import { useHistory } from 'react-router-dom';
import './StoreForm.css';
import { StoreFormDropDown } from './StoreFormDropDown';
import { urlConfig } from '../../utils/constants';
import { getAppEnv, isContactCenter } from 'omnia-utils-js';
import { getService } from '../../utils/baseApi';
import MyRedVest from '../../assests/images/MyRedVest2.png';
import StoreFormUtil from './StoreFormUtil';
import debounce from 'lodash/debounce';

export const StoreForm = (props) => {
  const env = getAppEnv();
  const history = useHistory();
  const [value, setValue] = useState('');
  const valueRef = useRef(value);
  // eslint-disable-next-line
  const [errorMessage, setErrorMessage] = useState('');
  const [storeDetails, setStoreDetails] = useState([]);
  const [open, setOpen] = useState(false);

  const onSearchClick = (event) => {
    if (!value) return;
    if (errorMessage) {
      event.preventDefault();
    } else {
      if (value) {
        event.preventDefault();
        if (event.key === 'Enter') {
          setOpen(true);
        }
        else {
          handleSearch(event);
        }
      }
    }
  };

  const handleSearch = (event) => {
    event.preventDefault();
    StoreFormUtil().setDataToLocalStorage(value, isContactCenter());
    StoreFormUtil().callGetTerminalApi(urlConfig[env], value.store.id);
    if (value && value.store.id) {
      history && history.push('/mrvshowroom/omnia/salesfloor');
    }
  };

  const getStoreInfo = async (val) => {
    let response = {};
    if (val && val.length) {
      try {
        const url = urlConfig[env] + '/omniab/store/getStoreList';
        const config = {
          headers: {
            'store-id': '',
          },
        };
        const storeResp = await getService(
          `${url}?query=${val}&maxResults=10&responseGroup=large`,
          config,
        );
        response = StoreFormUtil().validate(
          valueRef.current,
          val,
          storeResp,
          false,
        );
        if (response && response.data) {
          setErrorMessage(response?.error);
          setStoreDetails(response?.data);
        }
      } catch (error) {
        response = StoreFormUtil().validate(valueRef.current, val, error, true);
        if (response && response.data) {
          setErrorMessage(response?.error);
          setStoreDetails(response?.data);
        }
      }
    } else {
      setErrorMessage('');
      setStoreDetails([]);
    }
  };

  const inputChanged = (val) => {
    setErrorMessage('');
    setValue(val);
  };

  const setDropdownValue = (val) => {
    setValue(val);
  };

  const setOptionLabel = (option) => {
    return option?.store?.bis_name
      ? `${option?.store?.id +
      ' - ' +
      option?.store?.city +
      ', ' +
      option?.store?.state
      }`
      : option?.store?.id ? option?.store?.id : option;
  };
  const delayedCallback = useCallback(
    debounce((value) => {
      setOpen(true);
      getStoreInfo(value);
    }, 300),
    [],
  );
  useEffect(() => {
    valueRef.current = value;
    delayedCallback(value);
  }, [value]);

  return (
    <div className="d-flex-col">
      <img
        data-testid="myRedVest-image"
        src={MyRedVest}
        alt="logo"
        data-qtag-store-selection-image
      />
      <div
        className="store-title title-style"
        data-testid="store-title"
        data-qtag-store-selection-header
      >
        Store Sign In
      </div>
      <div
        className="store-subtitle title-style"
        data-testid="store-subtitle"
        data-qtag-store-selection-sub-header
      >
        Search fulfillment location to sign in to that store. - 1
      </div>
      <form className="input-container">
        <StoreFormDropDown
          stores={storeDetails}
          setOptionLabel={setOptionLabel}
          setValue={setDropdownValue}
          setInputValue={inputChanged}
          hasError={errorMessage?.length > 0}
          errorMessage={errorMessage}
          goToNextPage={onSearchClick}
          open={open}
          {...props}
        />
        <div
          className="search-button"
          data-testid="search-button"
          onClick={onSearchClick}
          data-qtag-store-search-button
        >
          <SearchIcon
            style={{ color: errorMessage?.length > 0 ? '#000' : '#fff' }}
          />
        </div>
      </form>
    </div>
  );
};
export default StoreForm;
