import { getService } from '../../utils/baseApi';

const StoreFormUtil = () => {
  const validate = (valRef, val, storeResp, hasError) => {
    const { logResponse, response } = storeResp;
    if (hasError) {
      if (valRef === val) {
        if (!isNaN(val) && val.length <= 3) {
          return {
            error: '',
            data: [],
          };
        } else if (response?.status === 400 || response?.status === 500) {
          return {
            error: 'Enter a valid Store #, City & State or Store Zip',
            data: [],
          };
        } else {
          return {
            error:
              'An Error Occurred while fetching the store list, Please try again or contact support if the problem persists',
            data: [],
          };
        }
      }
    } else {
      if (valRef === val) {
        if (logResponse && logResponse.data) {
          if (!isNaN(val) && val.length <= 3) {
            // when we have resposne available with val.length <= 3 digit of numeric input value then it should show empty options.
            return {
              error: '',
              data: [],
            };
          } else if (!isNaN(val) && val.length === 4) {
            // when we have resposne available for val.length === 4 which indicates store Id, then it should show options.
            const storeSort = logResponse.data.sort((a) =>
              a.store.id == val ? -1 : 1,
            );
            return {
              error: '',
              data: storeSort,
            };
          } else {
            return {
              error: '',
              data: logResponse.data,
            };
          }
        } else {
          if (!isNaN(val) && val.length <= 3) {
            // when we have no resposne with val.length <= 3 digit of numeric input then it should show empty options.
            return {
              error: '',
              data: [],
            };
          } else {
            return {
              error: 'Enter a valid Store #, City & State or Store Zip',
              data: [],
            };
          }
        }
      }
    }
  };

  const updateObjKeyName = (obj, old_key, new_key) => {
    if (old_key !== new_key) {
      Object.defineProperty(
        obj,
        new_key,
        Object.getOwnPropertyDescriptor(obj, old_key),
      );
      delete obj[old_key];
    }
    return obj;
  };

  const setDataToLocalStorage = (value, hasContactCenterFlag) => {
    localStorage.clear();
    const hardCodedRegInfo = JSON.stringify({
      registerInfo: {
        type: {
          value: '',
          description: '',
        },
      },
    });
    updateObjKeyName(value.store, 'address', 'address1');
    updateObjKeyName(value.store, 'lat', 'llat');
    updateObjKeyName(value.store, 'long', 'llong');
    value.store = { ...value.store, storeNumber: value.store.id };
    localStorage.setItem('storenumber', value.store.id);
    hasContactCenterFlag ? localStorage.setItem('serviceType', 'mrvB') : null;
    localStorage.setItem('storeName', value.store.bis_name);
    localStorage.setItem('storeZip', value.store.zip);
    localStorage.setItem('timeZone', value.store.timeZone)
    localStorage.setItem(
      'storeInfo',
      JSON.stringify({ storeInfo: value.store }),
    );
    localStorage.setItem('registerInfo', hardCodedRegInfo);
  };

  const callGetTerminalApi = async (baseUrl, storeId) => {
    const url = baseUrl + '/omniab/store/getTerminal/' + storeId;
    const config = {
      headers: {
        'store-id': storeId,
      },
    };
    try {
      const res = await getService(url, config);
      if (res && res.response) {
        localStorage.setItem('reg', res.response.terminalConfig.terminalNumber);
      }
    } catch (err) {
      console.log(`"reg" key not set correctly in localStorage \n ${err}`);
    }
    localStorage.setItem('clientName', 'salesfloor');
  };

  return {
    validate: validate,
    updateObjKeyName: updateObjKeyName,
    setDataToLocalStorage: setDataToLocalStorage,
    callGetTerminalApi: callGetTerminalApi,
  };
};

export default StoreFormUtil;
