import React, { useEffect } from 'react';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { ListboxComponent } from '../Utils/ListboxComponent';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import { setDkrnumberforErrors } from '../../utils/constants';

const useStyles = makeStyles((theme) => ({
  listbox: {
    boxSizing: 'border-box',
    boxShadow: 'none',
    overflow: 'hidden !important',
    maxHeight: '19rem',
    '&:hover': {
      overflow: 'auto !important',
    },
  },
  input: {
    fontSize: '24px',
    height: '64px !important',
    color: '#fff',
    backgroundColor: 'rgba(255, 255, 255, 0.1)',
    padding: '0 15px !important',
    fontFamily: 'Roboto',
    fontWeight: 'normal',
    lineHeight: '28px',
    letterSpacing: '0px',
  },
  errorInput: {
    fontSize: '24px',
    height: '64px !important',
    color: '#000',
    backgroundColor: '#fce9e8',
    padding: '0 15px !important',
    borderRadius: '4px !important',
    fontFamily: 'Roboto',
    fontWeight: 'normal',
    lineHeight: '28px',
    letterSpacing: '0px',
  },
  paper: {
    boxShadow: 'none',
    backgroundColor: 'transparent',
  },
  noOptionsText: {
    background: '#f9d4d2',
    borderRadius: '4px',
    textAlign: 'center',
    color: 'rgba(0, 0, 0, 0.8)',
    fontSize: '18px',
    fontFamily: 'Roboto',
    fontWeight: 'normal',
    lineHeight: '28px',
    letterSpacing: '0px',
    margin: ' 0 auto',
    width: '80%',
    marginTop: '30px',
  },
}));

export const StoreFormDropDown = (props) => {
  const classes = useStyles();

  useEffect(() => {
    if (props.hasError) {
      setDkrnumberforErrors();
    }
  }, [props.hasError]);
  return (
    <Autocomplete
      open={props.open}
      data-qtag-store-selection-store-input
      data-testid="store-number-finder"
      options={props.stores}
      classes={{
        listbox: classes.listbox,
        paper: classes.paper,
        input: props.hasError ? classes.errorInput : classes.input,
        noOptions: classes.noOptionsText,
      }}
      debug={true}
      ListboxComponent={ListboxComponent}
      ListboxProps={{
        'data-testid': 'list-box',
        className: 'store-list-options',
      }}
      getOptionLabel={(option) => props.setOptionLabel(option)}
      renderOption={(option) => (
        <div className="list-option" key={option.store.id}>
          <div className="option-store-info" style={{ fontSize: '20px' }}>
            {option.store.id} -{' '}
          </div>
          <div>
            <div className="option-store-info" style={{ fontSize: '20px' }}>
              {option.store.city + ', ' + option.store.state}
            </div>
            <div className="option-store-info" style={{ fontSize: '16px' }}>
              {option.store.address + ', ' + option.store.zip}
            </div>
          </div>
        </div>
      )}
      style={{ width: '100%' }}
      onChange={(unused, newValue) => {
        props.setValue(newValue);
      }}
      onInputChange={(event) => {
        props.setInputValue(event.target.value);
      }}
      closeIcon={''}
      popupIcon={''}
      freeSolo={props.hasError ? false : true}
      noOptionsText={props.hasError ? props?.errorMessage : ''}
      filterOptions={(options) => options}
      renderInput={(params) => (
        <TextField
          {...params}
          name="storeSearch"
          className="store-search"
          data-testid="store-search"
          placeholder="Store #, City & State, Zip Code"
          variant="outlined"
          autoFocus={true}
          error={props.hasError}
          onKeyPress={(e) => e.key === 'Enter' && props.goToNextPage(e)}
          style={{ marginBottom: props.hasError ? '30px' : '0px' }}
          data-qtag-store-selection-store-input-placeholder
        />
      )}
    />
  );
};
