import React from 'react';
import PropTypes from 'prop-types';
import AutoSizer from 'react-virtualized-auto-sizer';
import { List } from 'react-virtualized';

const ListboxComponent = React.forwardRef(function ListboxComponent(
  props,
  ref,
) {
  const { children } = props;
  const itemCount = Array.isArray(children) ? children.length : 0;
  const itemSize = 40;

  return (
    <div ref={ref} className="store-list-options">
      <AutoSizer>
        {({ height, width }) => (
          <List
            height={height}
            width={width}
            rowHeight={itemSize}
            overscanCount={5}
            rowCount={itemCount}
            rowRenderer={(props) => {
              return React.cloneElement(children[props.index], {
                style: props.style,
              });
            }}
            data-qtag-store-selection-store-option
          />
        )}
      </AutoSizer>
    </div>
  );
});

ListboxComponent.propTypes = {
  children: PropTypes.node,
};
